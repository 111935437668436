var scrolledTop = 0;
var screenHeight;
var leadInitiated = false;

var reveals = [];

$(function(){
	instantiateListeners();
	gatherReveals();
	renderWebPage();
	setupPage();
});

/* ===========================================================
	CHECK FOR MOBILE
=========================================================== */

function checkIsMobile() {
	if (window.matchMedia("(max-width: 991px)").matches) {
        return true;
    } else {
    	return false;
    }
}

/* ===========================================================
	INSTANTIATE LISTENERS
=========================================================== */

function instantiateListeners() {

	// On Scroll Event
	$(window).on('scroll',function(){
		if (window.requestAnimationFrame)
			window.requestAnimationFrame(renderWebPage);
		else if (window.msRequestAnimationFrame)
			window.msRequestAnimationFrame(renderWebPage);
		else if (window.webkitRequestAnimationFrame)
			window.webkitRequestAnimationFrame(renderWebPage);
		else if (window.mozRequestAnimationFrame)
			window.mozRequestAnimationFrame(renderWebPage);
		else if (window.oRequestAnimationFrame)
			window.oRequestAnimationFrame(renderWebPage);
		else {
			QueueNewFrame = function () {};
			scrollInterval = setInterval(function(){renderWebPage();}, 16.7);
		}
	});

	// On Window Resize
	$(window).resize(function(){
		setupPage();
		isMobile = checkIsMobile();
		renderWebPage();
	});
}

/* ===========================================================
	GATHER REVEALS
=========================================================== */

function gatherReveals() {
	$(document).find('.js-reveal, .js-revealRight, .js-revealUp, .js-revealLeft, .js-revealLine').each(function() {
		reveals.push({
			domObject : $(this),
			offset : $(this).offset().top,
			triggered: false
		});
	});
}

/* ===========================================================
	PAGE RENDER FUNCTION
===========================================================*/

function renderWebPage() {
	scrolledTop = $(window).scrollTop();

	if ( reveals.length ) {
		for ( var i = 0; i < reveals.length; i++ ) {
			if ( scrolledTop + screenHeight > reveals[i].offset && reveals[i].triggered === false ) {
				reveals[i].domObject.addClass('animateIn');
				reveals[i].triggered = true;
			}
		}
	}
}

/* ===========================================================
	SETUP PAGE
=========================================================== */

function setupPage() {
	screenHeight = $(window).height();
	for ( var i = 0; i < reveals.length; i++ ) {
		reveals[i].offset = reveals[i].domObject.offset().top;
	}
}

/*========================================================
	MOBILE NAV
===========================================================*/

var navbutton = $('.menu-btn');

navbutton.on("click", function(e){
    e.preventDefault();
    if($(this).hasClass('active')) {
        $(this).removeClass('active');
        $('nav ul#menu-main-nav').removeClass('active');
    } else {
        $(this).addClass('active');
        $('nav ul#menu-main-nav').addClass('active');
    }

    var dd_btn = $('#menu-main-nav .dd-btn');
    
    dd_btn.on('click', function(e) {
        e.preventDefault();

        var $this = $(this);

        $this.toggleClass('active');
        $this.closest('li').toggleClass('active');
    });
});

$(".mobil-nav").click(function(){
	$(this).toggleClass("is-active");
});

/* ===========================================================
	FIRST SECTION UNDER BANNER IMAGE OVERLAP
=========================================================== */

$(document).ready(function(){
	$(window).resize(function(){ 
	var windowHeight = $(window).height();
	var tenpercent = .1 * windowHeight; 
	$(document).scroll(function(){ 
		var y = $(this).scrollTop();
	
		if ( y > tenpercent) {
			$('section.img-l-text-r .img-block .img-wrap:nth-of-type(1)').addClass('active');
			$('.banner .title-wrap').addClass('active');
		} else {
			$('section.img-l-text-r .img-block .img-wrap:nth-of-type(1)').removeClass('active');
			$('.banner .title-wrap').removeClass('active');
		}
	});

	}).resize();
	
});

/* ===========================================================
	STICKY NAV
=========================================================== */

$(document).ready(function(){
	$(window).resize(function(){
	var windowHeight = $(window).height();
	var tenpercent = .01 * windowHeight;
	$(document).scroll(function(){
		var y = $(this).scrollTop();

		if( y > tenpercent && isMobile == false ) {
			$('.home-link').fadeOut("slow");
		} else if ( y < tenpercent ) {
			$('.home-link').fadeIn("slow");
		}
	});

	}).resize();
	
});

$(document).ready(function(){
	$(window).resize(function(){
	var windowHeight = $(window).height();
	var tenpercent = .1 * windowHeight;
	$(document).scroll(function(){
		var y = $(this).scrollTop();

		if( y > tenpercent && isMobile == false ) {
			$('header').addClass('fixed');
		} else if ( y < tenpercent ) {
			$('header').removeClass('fixed');
		}

		if ( $('header nav').height() < 23 ) {
			$('header').removeClass('fixed');
		}
	});

	}).resize();
	
});

/* ===========================================================
	PAGE SCROLL
=========================================================== */

$(document).on('click', '.js-inbound-link', function(e) {
	if ( !$('body').hasClass('sub-page') ) {

		var destination = $('#'+ $(this).attr('data-section')).offset().top - 120;
		$('body,html').animate({ scrollTop : destination }, 800);

		if ( $('header nav').height() > 100 ) {
			$('header').removeClass('show');
			$('body').removeClass('ms-prevent-scroll');
		}
	}
});

/* ===========================================================
	FORM VALIDATION
=========================================================== */

function validateForm($form) {  
	$form = (typeof $form !== 'undefined') ? $form : $(document).find('form.contact-form');

	var input, value;
	var noErrors = true;
	
	// Loop through each required field
	$form.find('.required:visible,input[required]:visible').each(function() {
		input = $(this);
		
		if ( !$(this).prop('disabled') ) {

			// Removes leading & trailing white-space
			value = input.val().replace(/^\s\s*/g, '').replace(/\s\s*$/g, '');
			
			// Validate non radio/checkbox fields
			if ( typeof input.attr('type') === 'undefined' || ( input.attr('type') !== 'undefined' && (input.attr('type') !== 'radio' && input.attr('type') !== 'checkbox') ) ) {			
				if ( typeof value === "undefined" ||  value === "" ) {
					input.addClass('invalid');
					noErrors = false;
				} else {
					input.removeClass('invalid');
				}
				
				// Extra email validation
				if ( (typeof input.attr('type') !== 'undefined' && input.attr('type') === 'email') || input.hasClass('email') ) {
					var filter = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;			
					if ( !filter.test(value) ) {
						input.addClass('invalid');
	      				noErrors = false;
					}  else {
						input.removeClass('invalid');
					}
				}
				
			// Validate radio/checkbox buttons
			} else {
				var inputName = input.attr('name');
				if ( $('input[name='+inputName+']:checked').length === 0 ) {
					noErrors = false;
				}
			}
		}
	});
	
	return noErrors;
}

/* ===========================================================
	FUNCTIONS - form submission messages
=========================================================== */

function displayThankYou() {
   $(".js-form-container").hide();

   $('.sorting-wrap').hide();


   $(".form-messages .js-thanks-container").show();
   $(".form-messages .js-oops-container").hide();
   $('.contact .form-copy').hide();
   $('.submit-wrapper .privacy').hide();
   $('body,html').animate({scrollTop:$(".form-messages .js-thanks-container").offset().top-200},200);

   submit_disabled = true;
}

function displayUhOh() {
	$('.sorting-wrap').hide();

   $(".form-messages .js-oops-container").show();
   $(".form-messages .js-thanks-container").hide();
   $('.form-copy .required-text').hide();
   $('.submit-wrapper  .privacy').hide();
   $('body,html').animate({scrollTop:$(".form-messages .js-oops-container").offset().top-200},200);
   submit_disabled = true;
}

/* ===========================================================
	FORM SUBMISSION
=========================================================== */

$('form.contact-form').submit(function(e) {
	e.preventDefault();
	if ( $('form').hasClass('submitting') ) {
		return false;
	}
	var validates = validateForm( $(this) );
	if ( validates ) {
		$('form.contact-form').addClass('submitting');
		$('form.contact-form .errors').hide();
		$('.availability-page form').removeClass('on');
		$('.send_message_overlay').removeClass('on');
		
		$.ajax({
		    url: 'https://go.tollbrothers.com/GenericWebLead/processlead',
		    data: $('form[name=regform]').serialize(),
		    type: 'POST',
		    dataType: 'JSONP'
		}).done(function(data) {
			if (data.success === 'true') {
				// Tracking code...
				var refNo = data.refNo;
				var cridVal = tbiReadCookie('crid');
				if ((cridVal != '' && refNo != '') && (cridVal != undefined && refNo != undefined)) {
					var cridPixel = '<div style="display:none;"><img src="https://go.tollbrothers.com/GenericWebLead/conversion.png?crid=' + cridVal + '&refNo=' + refNo + '" alt="convert pixel" /></div>';
					$('body').append(cridPixel);
				}
				try { dataLayer.push({'event': 'lead_complete'}); } catch(e) { console.log(e.message); }
				// End tracking code... 

			    displayThankYou();
			} else {
				displayUhOh();
			}
		}); // end ajax

	} else {
		$('form.contact-form .errors').show();
	}
	return false;
});

// Hide represented by a broker fieldset if represented by a broker fieldset answer is no

$(".js-showsection").on("change", function(e) {

	if ($(this).attr("name") == "broker") {
		if ($(this).val() == "yes") {
			$(".rep_broker").hide();
			$(".rep_broker input").prop( "disabled", true );
		} else {
			$(".rep_broker").show();
			$(".rep_broker input").prop( "disabled", false );
		}
	}

	if(typeof $(this).data('show') !== "undefined") {
		$("." + $(this).data('show')).show();
		$("." + $(this).data('show')).find("input").prop( "disabled", false );
	} else if (typeof $(this).data('hide') !== "undefined") {
		$("." + $(this).data('hide')).hide();
		$("." + $(this).data('hide')).find("input").prop( "disabled", true );
	}

});

// Hide labels on page load if inputs have values...
$(document).find('.hide-label').each(function() {
	if ( $(this).val() !== "" ) {
		$(this).parent().addClass('focused');
	}
});

if ( $('select.selector').val() !== "" ) {
	$('select.selector').parent().addClass('focused');
}

$(document).on('focus', '.required', function() {
	if ( leadInitiated === false && !$(this).hasClass('js-noLeadGen') && typeof dataLayer !== 'undefined' ) {
		leadInitiated = true;
		dataLayer.push({'event': "lead_initiated"});
	}
	$(this).removeClass('invalid');
	$('.errors').hide();
});

$('.hide-label').on('keyup', function(e) { 
	var value = $(this).val().replace(/^\s\s*/g, '').replace(/\s\s*$/g, '');
	if ( value !== "" ) {
		$(this).parent().addClass('focused');
	} else {
		$(this).parent().removeClass('focused');
	}
});

$('.selector').on('change', function(e) {
	if ( $(this).val() !== "" ) {
		$(this).parent().addClass('focused');
		$(this).removeClass('invalid');
		$('.errors').hide();
	} else {
		$(this).parent().removeClass('focused');
	}
});

$(document).on('click', '.radio-label', function(e) {
	if ( !isMobile && !$(this).hasClass('checked') ) {
		$(this).parent().find('.checked').removeClass('checked');
		$(this).addClass('checked');
	}
});

$(document).on('touchstart', '.radio-label', function(e) {
	if ( !$(this).hasClass('checked') ) {
		$(this).parent().find('.checked').removeClass('checked');
		$(this).addClass('checked');
	}
});

$('input[type=radio]').on('change', function(e) {
	if ( !$(this).is(':checked') ) {
		return false;
	}

	// Disable associated content
	var $disableContent = $('.'+$(this).attr('data-disable'));
	$disableContent.addClass('disabled').removeClass('focused');
	$disableContent.find('input[type=text]').each(function() {
		$(this).val('').attr('disabled','');
	});

	// Enable associated content
	if ( typeof $(this).attr('data-enable') !== 'undefined' ) {
		var $enableContent = $('.'+$(this).attr('data-enable'));
		$enableContent.removeClass('disabled');
		$enableContent.removeClass('focused');

		$enableContent.find('input[type=text]').each(function() {
			$(this).val('');
			$(this).removeClass('invalid');
			var thisID = $(this).attr('id');
			document.getElementById(thisID).disabled = false;
		});
	}
});

/* ===========================================================
	RADIO BUTTONS
=========================================================== */

$('input[type=radio]').on('change', function(e) {
	if ( !$(this).is(':checked') ) {
		return false;
	}

	// Disable associated content
	var $disableContent = $('.'+$(this).attr('data-disable'));
	$disableContent.addClass('disabled');
	$disableContent.find('.input-wrapper').each(function() {
		$(this).removeClass('focused');
		$(this).find('input[type=text]').val('').attr('disabled','');
	});

	// Enable associated content
	if ( typeof $(this).attr('data-enable') !== 'undefined' ) {
		var $enableContent = $('.'+$(this).attr('data-enable'));
		$enableContent.removeClass('disabled');
		$enableContent.find('fieldset').each(function() {
			$(this).removeClass('focused');
			$(this).find('input[type=text]').each(function() {
				$(this).val('');
				var thisID = $(this).attr('id');
				document.getElementById(thisID).disabled = false;
			});
		});
	}
});

/* ===========================================================
	AVAILABILITY PAGE FILTERING
=========================================================== */

function sortResidences(sortby, sortDir) {

	var sortMe = $(".sorting-wrap .listing");

	sortMe.detach().sort(function(a,b) {
		var aa = $(a).data(sortby);
		var bb = $(b).data(sortby);
		aa=String(aa).replace(/[,$]+/g, "").trim();
		bb=String(bb).replace(/[,$]+/g, "").trim();
		var updown = 0;

		if (sortDir == "asc") {
			updown = sortAlphaNum(aa, bb);
		} else {
			updown = sortAlphaNum(bb, aa);
		}
		return updown;
	});

	$(".sorting-wrap").append(sortMe);
}

$(document).on('click', '.js-sortResidences', function(e) {
	e.preventDefault();
	if ( $(this).hasClass('on') ) {
		if ( $(this).hasClass('asc') ) {
			$(this).removeClass('asc').addClass('desc');
			dir = "desc";
		} else {
			$(this).removeClass('desc').addClass('asc');
			dir = "asc";
		}
	} else {
		$(document).find('.js-sortResidences.on').removeClass('on').removeClass('asc').removeClass('desc');
		$(this).addClass('on').addClass('asc');
		dir = "asc";
	}
	sortResidences($(this).data("sortby"), dir);
});

var sortAlphaNum = function(a, b) {
    
	function chunkify(t) {
	  var tz = new Array();
	  var x = 0, y = -1, n = 0, i, j;
  
	  while (i = (j = t.charAt(x++)).charCodeAt(0)) {
		var m = (i == 46 || (i >=48 && i <= 57));
		if (m !== n) {
		  tz[++y] = "";
		  n = m;
		}
		tz[y] += j;
	  }
	  return tz;
	}
  
	var aa = chunkify(a.toLowerCase());
	var bb = chunkify(b.toLowerCase());
  
	for (x = 0; aa[x] && bb[x]; x++) {
	  if (aa[x] !== bb[x]) {
		var c = Number(aa[x]), d = Number(bb[x]);
		if (c == aa[x] && d == bb[x]) {
		  return c - d;
		} else return (aa[x] > bb[x]) ? 1 : -1;
	  }
	}
	return aa.length - bb.length;
	
  };

if ( $('body').hasClass('availability-page') ) {

	if ( window.location.hash != "" ) {
		var unit = window.location.hash.substr(1);
		var $unit = $('#unit'+unit);
		if ( $unit.length ) {
			var toTop = $unit.offset().top - $('header').height();
			$('body,html').animate({scrollTop: toTop},100);
		}
	}
} 

/* ===========================================================
	AVAILABILITY PAGE FORM
=========================================================== */

$('.request_more_info').on('click', function(e){
	e.preventDefault(e);
	$('.send_message_overlay').addClass('on');
	$('.availability-page form').addClass('on');
	$('header').removeClass('fixed');
});

$(document).on('click', '.availability-page form .close', function(e) {
	e.preventDefault();
	$('.availability-page form').removeClass('on');
	$('.send_message_overlay').removeClass('on');
});

$(document).on('click', '.send_message_overlay', function(e) {
	e.preventDefault();
	$('.availability-page form').removeClass('on');
	$('.send_message_overlay').removeClass('on');
});

/*==========================================================================
	CHANGES FORM TEXTAREA PLACEHOLDER TEXT BASED ON WHAT MODEL WAS CLICKED
==============================================================================*/

$('.availability-page .request_more_info').on('click', function() {
	name = $(this).attr('data-name');
    $('.availability-page textarea').val('I\'d like to enquire about The '+name);
});

/* ===========================================================
	FOOTER DISCLAIMER
=========================================================== */

$('.js-toggle-disclaimer').on('click', function(e){
	e.preventDefault();
	$('#disclaimer').toggle();
});

/* ===========================================================
	LAZY LOAD SINGLE POST IMAGES
=========================================================== */

$(document).ready(function() {
	var lazyloadImages;    
  
	if ("IntersectionObserver" in window) {
	  lazyloadImages = document.querySelectorAll(".lazy");
	  var imageObserver = new IntersectionObserver(function(entries, observer) {
		console.log(observer);
		entries.forEach(function(entry) {
		  if (entry.isIntersecting) {
			var image = entry.target;
			image.src = image.dataset.src;
			image.classList.remove("lazy");
			imageObserver.unobserve(image);
		  }
		});
	  }, {
		root: document.querySelector("#container"),
		rootMargin: "0px 0px 0px 0px"
	  });
  
	  lazyloadImages.forEach(function(image) {
		imageObserver.observe(image);
	  });
	} else {  
	  var lazyloadThrottleTimeout;
	  lazyloadImages = $(".lazy");
	  
	  function lazyload () {
		if(lazyloadThrottleTimeout) {
		  clearTimeout(lazyloadThrottleTimeout);
		}    
  
		lazyloadThrottleTimeout = setTimeout(function() {
			var scrollTop = $(window).scrollTop();
			lazyloadImages.each(function() {
				var el = $(this);
				if(el.offset().top < window.innerHeight + scrollTop ) {
				  var url = el.attr("data-src");
				  el.attr("src", url);
				  el.removeClass("lazy");
				  lazyloadImages = $(".lazy");
				}
			});
			if(lazyloadImages.length == 0) { 
			  $(document).off("scroll");
			  $(window).off("resize");
			}
		}, 20);
	  }
  
	  $(document).on("scroll", lazyload);
	  $(window).on("resize", lazyload);
	}
  })  